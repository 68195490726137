import Link from 'next/link'
import Image from 'next/image'

// BOOTSTRAP
import { Container, Row, Col } from 'react-bootstrap'

// STYLES
import styles from './index.module.css'

// STATIC DATA
import { weAreInCityData } from '@/staticData/about-we-are-in-cities.data'

// REDUX
import { useDispatch } from 'react-redux'
import apiDataSlice from '@/lib/slices/apiDataSlice'

const AboutWeAreIn = (): JSX.Element => {
  const dispatch = useDispatch()

  const onCityClick = () => {
      dispatch(apiDataSlice.actions.setAskServiceModal(true))
  }
  return (
    <div>
      <h2 className="text-center DARK__GOLDEN__COLOR">Serving In</h2>
      <p className="text-muted font_size_14 text-center">
        15+ Cities and counting
      </p>
      <br />
      <Container onClick={onCityClick}>
        <Row
          style={{ justifyContent: 'center', cursor: 'pointer' }}
          className="text-center"
        >
          {weAreInCityData.map(({ name, slug, imageSrc }) => (
            <Col key={name} className="mb-3 mt-2" xs={4} md={4} lg={2}>
              <Image
                src={imageSrc}
                alt={name}
                width={'120px'}
                height={'60px'}
                // unoptimized={true}
                className={styles.image}
              />
              <p
                className={`text-muted font-weight-bold mt-3 mb-0 ${styles.name__font__size}`}
              >
                {name}{' '}
                {slug === 'mumbai' ||
                slug === 'hyderabad' ||
                slug === 'bengaluru'
                  ? // <span className="badge badge-success">New</span>
                    null
                  : null}
              </p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default AboutWeAreIn
