import Image from 'next/image'

// STYLES
import styles from './index.module.css'

// BOOTSTRAP
import { Row, Col } from 'react-bootstrap'
import Link from 'next/link'
// TS INTERFACES
interface Props {
  image: string
  name: string
  onClick: () => void
  alt: string
}

const HomePageGridComponent = (props: Props): JSX.Element => {
  const { image, name, path, alt } = props
  return (
    <Col
      lg={3}
      md={3}
      xs={6}
      className={`text-center ${styles.cursor__pointer}`}
      // onClick={onClick}
    >
      <Row>
        <Col className={`p-2`}>
          <Link href={`${path}`}>
            <a style={{textDecoration:'none'}} target='_blank'>
              <div className={styles.img}>
                {image && (
                  <Image
                    src={image}
                    alt={alt}
                    className={styles.image}
                    width={300}
                    height={150}
                    objectFit="cover"
                    // unoptimized={true}
                  />
                )}

                <p
                  className={`text-muted font-weight-bold my-3 ${styles.name__font__size}`}
                >
                  {name}
                </p>
              </div>
            </a>
          </Link>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <p
            className={`text-muted font-weight-bold my-3 ${styles.name__font__size}`}
          >
            {name}
          </p>
        </Col>
      </Row> */}
    </Col>
  )
}

export default HomePageGridComponent
