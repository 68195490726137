// CSS
import styles from './index.module.css'

// BOOTSTRAP
import { Col, Row, Carousel } from 'react-bootstrap'

// TYPIST
// import Typist from 'react-typist'
// import TypistLoop from 'react-typist-loop'

// COMPONENTS
// import BookAChefBtn from '../BookAChefBtn/index'

// REDUX
import { useDispatch } from 'react-redux'
// import bookingModalSlice from '@/lib/slices/bookingModalSlice'

// GTM
// import { gtmEvent } from '@/lib/gtm'

interface Props {
  landingImageArray: { id: number; url: string; type: string }[]
}

const HomePageLanding = (props: Props): JSX.Element => {
  const { landingImageArray } = props
  const dispatch = useDispatch()
  const LANDING_IMG_URL =
    'https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/HeroImages/home.png'
  if (landingImageArray.length === 0) {
    landingImageArray.push({
      id: 0,
      url: LANDING_IMG_URL,
      type: 'homepage_carousel',
    })
  }
  const desktopImage = landingImageArray.filter(
    (arr) => arr.type === 'desktop_carousel'
  )
  const mobileImage = landingImageArray.filter(
    (arr) => arr.type === 'mobile_carousel'
  )
  return (
    <div>
      {/*--------------------- Desktop & Tablet device ---------------------*/}
      <div className={`${styles.landing__img__container} d-none d-sm-block`}>
        <Carousel indicators={true}>
          {desktopImage.map((image) => (
            <Carousel.Item interval={2000} key={image.id}>
              <img
                className="d-block w-100 homepage--landing--img mb-3"
                src={image.url}
                alt="Cooks and Chefs at Home"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      {/*--------------------- Mobile device ---------------------*/}
      <div className={`${styles.landing__img__container} d-block d-sm-none`}>
        <Carousel indicators={true}>
          {mobileImage.map((image) => (
            <Carousel.Item interval={2000} key={image.id}>
              <img
                className="d-block w-100 homepage--landing--img mb-3"
                src={image.url}
                alt="Cooks and Chefs at Home"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default HomePageLanding
