// COMPONENTS
import HomePageGridList from './HomePageGridList'

// TS INTERFACES
interface Props {
  heading: string
  subHeadingJSX?: JSX.Element
  headingOnClickURL?: string
  data: Array<any>
  // onClick: (id: number) => void
  loading: boolean
  type?: 'ocl'
}

const HomePageGridFour = (props: Props): JSX.Element => {
  return <HomePageGridList {...props} />
}

export default HomePageGridFour
