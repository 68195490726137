// TODO: replace img with next Image when new correct images are given

// import Image from 'next/image'

// STYLES
import styles from './index.module.css'
import Image from 'next/image'
// BOOTSTRAP
import { Row, Col, Container } from 'react-bootstrap'
import { FaUserCheck, FaCheckSquare, FaGrinStars } from 'react-icons/fa'

const wrapS3URLForIcons = (imageName: string) => {
  return `https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/icons/${imageName}`
}

const HomepageWhyBookAChef = (props: any): JSX.Element => {
  return (
    <Container className="mt-5">
      <h2 className={`text-center mb-4 DARK__GOLDEN__COLOR`}>
        {props.heading}
      </h2>
      <br />
      <Row>
        <Col md={4} xs={4} className="text-center">
          <FaUserCheck size={28} />
          <h3
            className={`${styles.font__size__25} text-muted font-weight-bold`}
          >
            {/* <Image
              src={wrapS3URLForIcons('hotAndfresh.png')}
              alt="Hot & Fresh"
              // width={"52px"}
              // height={"52px"}
              // style={{ height: '52px', width: '52px' }}
              className="mb-3 mt-1"
              height={75}
              width={75}
              // unoptimized={true}
            />{' '} */}

            <br />
            <br />
            <span>Verified Professionals</span>
          </h3>
          <br />
        </Col>

        <Col md={4} xs={4} className="text-center">
          <FaCheckSquare size={28} />
          <h3
            className={`${styles.font__size__25} text-muted font-weight-bold`}
          >
            {/* <Image
              src={wrapS3URLForIcons('relax.png')}
              alt="Home Comfort"
              // width={"52px"}
              // height={"52px"}
              // style={{ height: '52px', width: '52px' }}
              className="mb-3 mt-1"
              height={75}
              width={75}
              // unoptimized={true}
            />{' '} */}

            <br />
            <br />
            <span>Assured Service</span>
          </h3>
          <br />
        </Col>

        <Col md={4} xs={4} className="text-center">
          <FaGrinStars size={28} />
          <h3
            className={`${styles.font__size__25} text-muted font-weight-bold`}
          >
            {/* <Image
              src="https://coox-beta.s3.ap-south-1.amazonaws.com/images/website/cooks/quantity.png"
              alt="Good Quantity"
              // width={"52px"}
              // height={"52px"}
              // style={{ height: '52px', width: '52px' }}
              className="mb-3 mt-1"
              height={75}
              width={75}
              // unoptimized={true}
            />{' '} */}

            <br />
            <br />
            <span>Amazing Experience</span>
          </h3>
          <br />
        </Col>
      </Row>
    </Container>
  )
}

export default HomepageWhyBookAChef
