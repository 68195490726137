import Head from 'next/head'
import { useRouter } from 'next/router'
import { GetStaticProps } from 'next'
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

// BOOTSTRAP AND CSS
import styles from './index.module.css'

// REACT QUERY
import { useQuery } from 'react-query'

// FETCHERS
import { fetchOccasions, fetchCuisines } from '@/fetchers/apiDataFetchers'

// REDUX
import { useDispatch } from 'react-redux'
import apiDataSlice from '@/lib/slices/apiDataSlice'
import bookingModalSlice from '@/lib/slices/bookingModalSlice'
import bookingBartenderModalSlice from '@/lib/slices/bookingBartenderModalSlice'
import bookingWaiterModalSlice from '@/lib/slices/bookingWaiterModalSlice'
import bookingCleanerModalSlice from '@/lib/slices/bookingCleanerModalSlice'
import bookDailyCookModalSlice from '@/lib/slices/bookDailyCookModalSlice'
import authSlice from '@/lib/slices/authSlice'

// Components
import HomePageLanding from '@/components/HomePageLanding'
import HomePageGrid from '@/components/HomePageGrid'
import TopRatedChefs from '@/components/TopRatedChefs'
import WhyBookAChef from '@/components/MainHomepageWhyBookAChef'
// import FoodiesLoveUs from '@/components/FoodiesLoveUs'
import Gallery from '@/components/HomepageGallery'
import ServiceStats from '@/components/ServiceStats'
import ExperienceCOOXCTA from '@/components/ExperienceCOOXCTA'
import SpinningHashLoader from '@/components/SpinningHashLoader'
import WeAreIn from '@/components/AboutWeAreIn'
const FoodiesLoveUs = dynamic(() => import('@/components/FoodiesLoveUs'))
// STATIC DATA
import { services } from '../staticData/main-homepage-services.data'
import { reviews, chefReviews } from '../staticData/foodies-love-us.data'

import HomePageGridFour from '@/components/HomePageGridFour'
// HOCs
// import withAuth from 'HOCs/withAuth'

// TS INTERFACES
import { Ioccasion } from '@/lib/rootState'
import { Icook } from '@/types/cookTypes'
interface Props {
  topCooks: Icook[]
  landingImageArray: { id: number; url: string; type: string }[]
  occasions: Ioccasion[]
}

import axios from 'axios'
import Link from 'next/link'
import LiveCounters from '@/components/HomePageGrid/LiveCounter'
import PeopleAlsoBooked from '@/components/PeopleAlsoBooked'

const Home = (props: Props): JSX.Element => {
  const { topCooks, landingImageArray, occasions, combos, restaurants } = props
  // console.log(restaurants)
  const router = useRouter()
  const { query } = router
  const dispatch = useDispatch()
  const [loginUsingLink, setLoginUsingLink] = useState(false)
  useEffect(() => {
    if (query.action === 'book-a-chef') {
      dispatch(bookingModalSlice.actions.toggleBookingModal())
    }
    if (query.action === 'book-a-bartender') {
      dispatch(bookingBartenderModalSlice.actions.toggleBookingBartenderModal())
    }
    if (query.action === 'book-a-waiter') {
      dispatch(bookingWaiterModalSlice.actions.toggleBookingWaiterModal())
    }
    if (query.action === 'book-a-cleaner') {
      dispatch(bookingCleanerModalSlice.actions.toggleBookingCleanerModal())
    }
    if (query.action === 'book-daily-cook') {
      dispatch(bookDailyCookModalSlice.actions.toggleBookDailyCookModal())
    }
    // redirect user to ingredients page, if they have the login link to ingredients page
    if (router.pathname === '/' && query.login_token) {
      setLoginUsingLink(true)
      const { booking_id } = query
      axios({
        method: 'POST',
        url: `/auth/login-and-signup`,
        data: {
          token: router.query.login_token,
          source: 'user_website',
        },
      })
        .then((res) => {
          setLoginUsingLink(false)
          // set into redux store
          dispatch(authSlice.actions.authUserSet(res.data.user))
          dispatch(authSlice.actions.setIsLoggedIn(true))

          // set into localstorage
          localStorage.setItem('authUser', JSON.stringify(res.data.user))
          localStorage.setItem('authToken', JSON.stringify(res.data.token))

          localStorage.setItem('accessToken', res.data.token.accessToken)
          localStorage.setItem('refreshToken', res.data.token.refreshToken)
          localStorage.setItem('expiresIn', res.data.token.expiresIn)
          localStorage.setItem('mobile', res.data.user.mobile)
          if (query.event === 'payAdvance') {
            router.replace(
              `/my-bookings?show=cart&bookingId=${booking_id}&event=payAdvance`
            )
          } else if (query.event === 'view_cart') {
            router.replace(`/my-bookings?show=cart`)
          } else if (query.event === 'booking_rating') {
            router.replace(
              `/my-bookings?bookingId=${booking_id}&event=giveRating`
            )
          } else if (query.event === 'payBalance') {
            router.replace(
              `/my-bookings?bookingId=${booking_id}&event=payBalance`
            )
          } else if (query.event === 'booking_ingredients') {
            router.replace(
              `/my-bookings?bookingId=${booking_id}&event=ingredients`
            )
          } else if (query.event === 'booking_menu') {
            router.replace(`/my-bookings?bookingId=${booking_id}&event=menu`)
          } else if (query.event === 'booking_appliances') {
            router.replace(
              `/my-bookings?bookingId=${booking_id}&event=appliances`
            )
          } else if (query.event === 'cancel_booking') {
            router.replace(
              `/my-bookings?bookingId=${booking_id}&event=cancelBooking`
            )
          } else if (query.event === 'booking_details') {
            router.replace(
              `/my-bookings?bookingId=${booking_id}&event=bookingDetails`
            )
          }
        })
        .catch((err) => {
          setLoginUsingLink(false)

          const errorMsg = err.response && err.response.data.message
          console.log(errorMsg)
          router.replace('/')
        })

      // return <h1>Trying to log you in... </h1>
    }
  }, [query])

  const { isLoading: loadingOccasions, data: occasionsData } = useQuery(
    'occasions',
    () => fetchOccasions(),
    {
      staleTime: 300000, // 5 mins
      refetchOnWindowFocus: false,
    }
  )
  // const { isLoading: loadingCombos, data: combosData } = useQuery(
  //   'combos',
  //   () => fetchCombos(),
  //   {
  //     staleTime: 300000, // 5 mins
  //     refetchOnWindowFocus: false,
  //   }
  // )

  const { data: cuisinesData } = useQuery('cuisines', () => fetchCuisines(), {
    staleTime: 300000, // 5 mins
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    /**
     * Set published occasions to REDUX state so that it can be used in bookingModal
     */
    if (occasionsData) {
      dispatch(apiDataSlice.actions.setOccasions(occasionsData.data))
    }
  }, [occasionsData])

  useEffect(() => {
    /**
     * Set combos to REDUX state so that it can be used in bookingModal
     */
    if (combos) {
      dispatch(apiDataSlice.actions.setCombos(combos))
    }
  }, [combos])

  useEffect(() => {
    /**
     * Set cuisines to REDUX state so that it can be used in bookingModal
     */
    if (cuisinesData) {
      dispatch(apiDataSlice.actions.setCuisines(cuisinesData.data.cuisines))
    }
  }, [cuisinesData])

  /* -----------LOGGING USING LINK----- */
  if (loginUsingLink) {
    return (
      <>
        <br />
        <br />
        <SpinningHashLoader />
      </>
    )
  }

  // Subheading JSX
  const occasionsSubheadingJSX = (
    <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
      We cater to all kind of{' '}
      <Link href="/occasions">
        <a target="_blank">occasions</a>
      </Link>
    </p>
  )
  const cuisinesSubheadingJSX = (
    <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
      Choose from worldwide{' '}
      <Link href="/cuisine">
        <a target="_blank">cuisines</a>
      </Link>{' '}
      and{' '}
      <Link href="/menu">
        <a target="_blank">menu</a>
      </Link>
    </p>
  )

  const servicesSubheadingJSX = (
    <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
      Popular services to choose from
    </p>
  )

  const liveCounterJSX = (
    <p className={`text-muted text-center mb-4 ${styles.font__size__14}`}>
      Enjoy chef specialties live at home
    </p>
  )

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <title>
          Book a Cook near you, Chef for Birthday, House Party - COOX
        </title>
        <meta
          name="description"
          content="Hire professional Cooks, personal Chefs for home for Birthdays, House Parties. Call 9004044234. Top service in Delhi NCR, Bangalore, Mumbai, Hyderabad."
          key="description"
        />
        {/* <!-- Google / Search Engine Tags --> */}
        <meta
          itemProp="name"
          content="Book a Cook near you, Chef for Birthday, House Party - COOX"
        />
        <meta
          itemProp="description"
          content="Hire professional Cooks, personal Chefs for home for Birthdays, House Parties. Call 9004044234. Top service in Delhi NCR, Bangalore, Mumbai, Hyderabad."
        />
        <meta
          itemProp="image"
          content="https://coox-new.s3.ap-south-1.amazonaws.com/images/coox-logo.jpg"
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://www.coox.in" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Book a Cook near you, Chef for Birthday, House Party - COOX"
        />
        <meta
          property="og:description"
          content="Hire professional Cooks, personal Chefs for home for Birthdays, House Parties. Call 9004044234. Top service in Delhi NCR, Bangalore, Mumbai, Hyderabad."
        />
        <meta
          property="og:image"
          content="https://coox-new.s3.ap-south-1.amazonaws.com/images/coox-logo.jpg"
        />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Book a Cook near you, Chef for Birthday, House Party - COOX"
        />
        <meta
          name="twitter:description"
          content="Hire professional Cooks, personal Chefs for home for Birthdays, House Parties. Call 9004044234. Top service in Delhi NCR, Bangalore, Mumbai, Hyderabad."
        />
        <meta
          name="twitter:image"
          content="https://coox-new.s3.ap-south-1.amazonaws.com/images/coox-logo.jpg"
        />

        {/* Schema */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: 'COOX',
              url: 'https://www.coox.in',
              sameAs: [
                'https://www.facebook.com/cooxonline/',
                'https://twitter.com/cooxonline',
                'https://linkedin.com/company/cooxonline',
                'https://www.youtube.com/channel/UCKc9QdEbYsibls1E_tTA-sA',
                'https://www.instagram.com/coox.in/',
              ],
              logo: 'https://coox-new.s3.ap-south-1.amazonaws.com/images/coox-logo.jpg',
            }),
          }}
        />
      </Head>
      {/* ----------- LANDING ---------- */}
      <HomePageLanding landingImageArray={landingImageArray} />

      {/* ----------- SERVICES ---------- */}
      <HomePageGridFour
        heading="Services"
        data={services}
        // onClick={() => {
        //   dispatch(bookingModalSlice.actions.toggleBookingModal())
        // }}
        loading={false}
      />
      <hr className={styles.hr} />
      {/* <hr className={styles.hr} /> */}

      {/* ----------- OCCASIONS ---------- */}
      <HomePageGrid
        heading="Occasions"
        subHeadingJSX={occasionsSubheadingJSX}
        data={occasions?.filter((occasion: Ioccasion) => occasion.published)}
        onClick={(id) => {
          dispatch(apiDataSlice.actions.setAskServiceModal(true))
          // dispatch(bookingModalSlice.actions.toggleBookingModal())
          // dispatch(bookingModalSlice.actions.setOccasionId(id))
        }}
        loading={false}
      />
      <hr className={styles.hr} />
      {/* <hr className={styles.hr} /> */}

      {/* ----------- CUISINES ---------- */}
      <HomePageGrid
        heading="Chef De Cuisine"
        subHeadingJSX={cuisinesSubheadingJSX}
        data={combos}
        onClick={(id) => {
          window.open('/cook?action=book-a-chef')
          // dispatch(bookingModalSlice.actions.toggleBookingModal())
          // dispatch(
          //   bookingModalSlice.actions.setMenuPreferences({ comboId: id })
          // )
        }}
        loading={false}
      />
      <hr className={styles.hr} />
      {/* <hr className={styles.hr} /> */}

      {/* --------LIVE COUNTERS---------- */}

      {/* <LiveCounters
        heading="Live Counters"
        subHeadingJSX={liveCounterJSX}
        loading={false}
      /> */}

      {/* ----------- HOW IT WORKS ---------- */}
      {/* <HowItWorks /> */}
      {/* ----------- TOP RATED CHEFS ---------- */}
      <TopRatedChefs heading="Top Rated Professionals" topCooks={topCooks} />
      <br></br>
      <hr className={styles.hr} />

      <br />
      <WeAreIn />
      <br></br>
      <hr className={styles.hr} />
      {/* ----------- FAMOUS HOTELS AND RESTAURANTS ---------- */}
      {/* <FamousHotelsAndRestaurants restaurants={restaurants} />
      <br></br>
      <hr className={styles.hr} /> */}

      {/* -----------DAILY COOK / PARTY CHEF---------- */}
      {/* <DailyCookPartyChef />

      <hr className={styles.hr} /> */}
      {/* ------------ COVID GUIDELINES -------- */}
      <PeopleAlsoBooked
        heading="People also Booked"
        subHeadingJSX={servicesSubheadingJSX}
        hide="cook"
        loading={false}
      />
      <br />
      <hr className={styles.hr} />
      <WhyBookAChef heading="Why COOX ?" />
      <hr className={styles.hr} />
      {/* <br /> */}

      {/* ----------- WHY BOOK A chef ---------- */}
      {/* <CommitedToYourSafety />
      <br></br>
      <hr className={styles.hr} /> */}

      <br />

      {/* ----------- FOODIES LOVE US ---------- */}
      <FoodiesLoveUs reviews={chefReviews} />

      {/* <hr className={styles.hr} /> */}

      {/* --------GIFT AN EXPERIENCE--------- */}
      {/* <GiftAnExperience /> */}

      <br />
      <br></br>
      <hr className={styles.hr} />
      {/* ----------- GALLERY ---------- */}
      <Gallery />

      {/* ---------SERVICE STATS--------- */}
      <br />
      <br />
      <hr className={styles.hr} />
      <ServiceStats />

      {/* ----------- BOOK A CHEF BTN ---------- */}
      <ExperienceCOOXCTA
        setAskServicesModal={() => {
          dispatch(apiDataSlice.actions.setAskServiceModal(false))
        }}
      />
    </div>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const response = await axios.get(`/cook/top-rated-cooks?_limit=10`)
  const res2 = await axios.get('/website/image')
  const res3 = await axios.get('/occasions')
  const res4 = await axios.get('/cuisines/combos')
  const res5 = await axios.get('/website/cook-restaurants')
  return {
    props: {
      topCooks: response.data.data.top_cooks,
      landingImageArray: res2.data.images,
      occasions: res3.data,
      combos: res4.data.combos.filter(
        ({ published }: { published: boolean }) => published
      ),
      restaurants: res5.data.restaurants,
    },
    revalidate: 60,
  }
}

export default Home
