// REACT spinners
import { css } from '@emotion/core'
import { HashLoader } from 'react-spinners'

// TS INTERFACES
interface Props {
  size?: number
  color?: string
}

const SpinningHashLoader = (props: Props): JSX.Element => {
  const { size = 80, color = '#F0C600' } = props

  return <HashLoader css={override} size={size} color={color} loading />
}

export default SpinningHashLoader

// HASH LOADER CSS
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`
