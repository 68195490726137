import Link from 'next/link'

// BOOTSTRAP
import { Row, Container } from 'react-bootstrap'

// COMPONENTS
import HomePageGridComponent from '../HomePageGridItem'
import GridLoader from '../GridLoader/index'

interface Props {
  heading: string
  subHeadingJSX?: JSX.Element
  headingOnClickURL?: string
  data: Array<any>
  // onClick: (id: number) => void
  loading: boolean
  type?: 'ocl'
}

const OccasionsGrid = (props: Props): JSX.Element => {
  const {
    heading,
    headingOnClickURL,
    data,
    // onClick,
    loading,
    type,
    subHeadingJSX,
  } = props

  return (
    <Container className={type === 'ocl' ? 'px-0' : ''}>
      {type === 'ocl' ? (
        <>
          <h2 className="FONT__SIZE__20 font-weight-bolder" id="services">
            {heading}
          </h2>
          {subHeadingJSX}
          <br />
        </>
      ) : headingOnClickURL ? (
        <Link href={headingOnClickURL}>
          <a className="TEXT_DECORATION_NONE">
            <h1 className={`text-center mt-5 DARK__GOLDEN__COLOR`}>
              {heading}
            </h1>
            {subHeadingJSX}
          </a>
        </Link>
      ) : (
        <>
          <h1 className={`text-center mt-5 DARK__GOLDEN__COLOR`}>{heading}</h1>
          {subHeadingJSX}
        </>
      )}

      {loading && <GridLoader />}
      <Row>
        {data?.map((item) => {
          const { id, name, image, path } = item
          let alt = name;
          if(heading === "Occasions") alt = `Find hire top rated cooks chefs for ${name} occasion via COOX. Book highly trained professional chefs for house parties and other occasions online`
          else if(heading === "Cuisines") alt = `Book a personal chef to make ${name} cusine at home. Hire top rated and verified cooks chefs at home for small house parties, private parties via COOX`
          else alt = item?.alt
          return (
            <HomePageGridComponent
              key={id}
              name={name}
              image={image}
              alt={alt}
              path={path}
              // onClick={() => onClick(id)}
            />
          )
        })}
      </Row>
    </Container>
  )
}

export default OccasionsGrid
