import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from './index.module.css'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const Ticker = ({ className, ...rest }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false)

  return (
    <CountUp {...rest} start={viewPortEntered ? null : 0}>
      {({ countUpRef }) => {
        return (
          <VisibilitySensor
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true)
              }
            }}
            delayedCall
          >
            <h4 className={className} ref={countUpRef} />
          </VisibilitySensor>
        )
      }}
    </CountUp>
  )
}

const ServiceStats = (): JSX.Element => {
  return (
    <div className="container">
      <br />
      <br />
      <br />
      <Row className="justify-content-around">
        <Stat title="People Served" value="50000+" />
        <Stat title="Professionals" value="3000+" />
        <Stat title="Cities" value="15+" />
      </Row>
    </div>
  )
}

interface Props {
  title: string
  value: string
}

const Stat = (props: Props): JSX.Element => {
  const { title, value } = props
  return (
    <Col xs="auto" className="text-center p-0">
      <Ticker
        className={styles.Value}
        end={parseInt(value)}
        suffix="+"
        duration={2}
      />
      <br />
      <p className={`text-muted font-weight-bold ${styles.Title}`}>{title}</p>
      <br />
      {/* <hr className={styles.BottomLine} /> */}
      <br />
      <br />
    </Col>
  )
}

export default ServiceStats
